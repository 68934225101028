<template>
    <div class="company-setting">
        <div class="company-setting-container">
            <ul class="company-setting-tab">
                <li :class="activeTab == 0 ? 'active' : ''" @click="changeActiveTab(0)">资料设置</li>
                <li :class="activeTab == 1 ? 'active' : ''" @click="changeActiveTab(1)" v-if="false">标签设置</li>
            </ul>

            <div class="company-setting-main" v-show="activeTab == 0">
                <div class="company-setting-content" v-loading="loading">
                    <div class="info info-top">
                        <div class="info-text">
                            <h3>
                                公司信息
                                <span v-if="!infoEditing" @click="showInfoForm"><i class="el-icon-edit-outline"></i>编辑</span>
                            </h3>
                            <div class="info-content">
                                <ul v-if="!infoEditing" class="info-text-list">
                                    <li><span>行业：</span>{{companyInfo.industry}}</li>
                                    <li><span>规模：</span>{{companyInfo.companyScaleDescription}}</li>
                                    <li><span>城市：</span>{{companyInfo.location}}</li>
                                    <li><span>地址：</span>{{companyInfo.address}}</li>
                                    <li>
                                        <span>主页：</span>
                                        <a v-if="companyInfo.website" :href="companyInfo.website" target="_blank">{{companyInfo.website}}</a>
                                    </li>
                                </ul>
                                <el-form v-else class="info-form" ref="infoForm" :model="infoForm" label-width="150px" :rules="infoFormRules" v-loading="infoFormLoading">
                                    <el-form-item label="行业" required>
                                        <el-row>
                                            <el-col :span="8">
                                                <el-form-item prop="industryLevel1Id">
                                                    <el-select v-model="infoForm.industryLevel1Id" placeholder="请选择" @change="handleIndustryelect">
                                                        <el-option label="请选择" value="-1"></el-option>
                                                        <el-option v-for="industry in industryArray" :key="industry.code" :label="industry.name" :value="industry.code.toString()"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8" class="second-select">
                                                <el-form-item prop="industryId">
                                                    <el-select v-model="infoForm.industryId" placeholder="请选择">
                                                        <el-option label="请选择" value="-1"></el-option>
                                                        <el-option v-for="(subIndustry, code) in subIndustryObj" :key="code" :label="subIndustry" :value="code.toString()"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>
                                    <el-form-item label="规模" prop="companyScale" required>
                                        <el-select v-model="infoForm.companyScale" placeholder="请选择">
                                            <el-option label="请选择" value="-1"></el-option>
                                            <el-option v-for="(companyScale, code) in companyScaleData" :key="code" :label="companyScale" :value="code.toString()"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="城市" required>
                                        <el-row>
                                            <el-col :span="8">
                                                <el-form-item prop="province">
                                                    <el-select placeholder="请选择" :value="provinceSelected" @change="handleProvinceSelect">
                                                        <el-option label="请选择" value="-1"></el-option>
                                                        <el-option v-for="province in provinceCityData" :key="province.value" :label="province.label" :value="province"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8" class="second-select">
                                                <el-form-item prop="city">
                                                    <el-select v-model="infoForm.city" placeholder="请选择">
                                                        <el-option label="请选择" value="-1"></el-option>
                                                        <el-option v-for="city in cityList" :key="city.value" :label="city.fullLabel || city.label" :value="city.value.toString()"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>
                                    <el-form-item label="地址" prop="address">
                                        <el-input v-model="infoForm.address" placeholder="请输入详细地址"></el-input>
                                    </el-form-item>
                                    <el-form-item label="主页" prop="website">
                                        <el-input v-model="infoForm.website" placeholder="请输入主页网址"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="submitForm('infoForm')">保存</el-button>
                                        <el-button @click="resetForm('infoForm')">取消</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <div class="info-text desc-text">
                            <h3>
                                公司介绍
                                <span v-if="!descEditing" @click="showDescForm"><i class="el-icon-edit-outline"></i>编辑</span>
                            </h3>
                            <div class="info-content">
                                <span v-if="!descEditing">{{companyInfo.description}}</span>
                                <el-form v-else class="info-form" ref="descForm" :model="descForm" :rules="descFormRules" v-loading="descFormLoading">
                                    <el-form-item prop="description">
                                        <el-input type="textarea" v-model="descForm.description" placeholder="请输入公司介绍"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="submitForm('descForm')">保存</el-button>
                                        <el-button @click="resetForm('descForm')">取消</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <div class="info-text company-setting-members">
                            <h3>
                                公司成员({{memberCount}})
                            </h3>
                            <div class="info-content">
                                <!-- <ul>
                                    <li v-for="member in memberList" :key="member.id">
                                        <img v-if="member.avatarUrl" :src="member.avatarUrl" />
                                        <img v-else src="~@src/assets/images/default-user.png" />
                                    </li>
                                </ul> -->
                                <ul>
                                    <li v-for="member in memberList" :key="member.userId">
                                        <img v-if="member.avatarUrl" :src="member.avatarUrl" />
                                        <img v-else src="~@src/assets/images/default-user.png" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="company-setting-labels" v-show="activeTab == 1">
                <!-- 功能停用，代码地址：Baza.LieBiDe.FrontEnd\src\component\page\label-manage\label-manage.tpl -->
                <img src="~@src/assets/images/no-open.png" alt="">
                <p>该功能暂未开放~</p>
            </div>
        </div>
    </div>
</template>

<script>
import industryData from '#/js/config/industryMap.json';
import companyScaleData from '#/js/config/companyScale.json';
import provinceThreeData from '#/js/config/provinceThreeData.json';
import { team as teamUrl } from '#/js/config/api.json';

export default {
    data() {
        var validateIndustry = (rule, value, callback) => {
            if (value === '' || value === '-1') {
                callback(new Error('请选择所属一级行业'));
            } else {
                callback();
            }
        };
        var validateSubIndustry = (rule, value, callback) => {
            if (value === '' || value === '-1') {
                callback(new Error('请选择所属二级行业'));
            } else {
                callback();
            }
        };
        var validateCompanyScale = (rule, value, callback) => {
            if (value === '' || value === '-1') {
                callback(new Error('请选择公司规模'));
            } else {
                callback();
            }
        };
        return {
            activeTab: 0,
            companyInfo: {},
            memberList: [],
            memberCount: 0,
            infoEditing: false,
            descEditing: false,
            loading: false,
            infoFormLoading: false,
            descFormLoading: false,
            industryArray: industryData.industryArray,
            industryIdMap: industryData.industryIdMap,
            subIndustryObj: {},
            companyScaleData: companyScaleData,
            provinceCityData: provinceThreeData.province,
            cityList: [],
            provinceSelected: '',
            infoForm: {
                industryId: '',
                industryLevel1Id: '',
                companyScale: '',
                province: '',
                city: '',
                address: '',
                website: ''
            },
            infoFormRules: {
                industryLevel1Id: [
                    { validator: validateIndustry, trigger: 'change' }
                ],
                industryId: [
                    { validator: validateSubIndustry, trigger: 'change' }
                ],
                companyScale: [
                    { validator: validateCompanyScale, trigger: 'change' }
                ],
                province: [
                    { required: 'true', message: '请选择所在省份', trigger: 'change' }
                ],
                city: [
                    { required: 'true', message: '请选择所在城市', trigger: 'change' }
                ],
                address: [
                    { required: 'true', message: '请输入详细地址', trigger: ['blur', 'change'] }
                ]
            },
            descForm: {
                description: ''
            },
            descFormRules: {
                description: [
                    { required: 'true', message: '请输入公司介绍', trigger: ['blur', 'change'] }
                ]
            }
        }
    },
    computed: {
        token() {
            return this.$store.state.verificationToken.verificationToken
        }
    },
    mounted() {
        this.getCompanyInfo();
        this.getMemberList();
    },
    methods: {
        changeActiveTab(value) {
            if(this.activeTab == value) {
                return false;
            }
            this.activeTab = value;
        },

        handleIndustryelect(value) {
            if(value === '-1') {
                this.subIndustryObj = {};
            }else {
                this.subIndustryObj = this.industryIdMap[value];
            }
        },

        handleProvinceSelect(province) {
            if(province === '-1') {
                this.provinceSelected = '请选择'
                this.infoForm.province = '';
                this.cityList = [];
                this.$refs.infoForm.validateField('province');
            }else {
                this.provinceSelected = province.label;
                this.infoForm.province = province.value;
                this.cityList = province.children;
            }
            this.infoForm.city = '';
        },

        getCompanyInfo() {
            this.laoding = true;
            _request({
                url: '/MyCompany/Detail?isNewPage=true',
                method: 'GET'
            }).then(res => {
                this.companyInfo = res;

                this.infoForm = {
                    industryId: res.industryId ? res.industryId.toString() : '',
                    industryLevel1Id: res.industryLevel1Id ? res.industryLevel1Id.toString() : '',
                    companyScale: res.companyScale ? res.companyScale.toString() : '',
                    province: res.province ? res.province.toString() : '',
                    city: res.city ? res.city.toString() : '',
                    address: res.address || '',
                    website: res.website || ''
                }

                if(this.infoForm.industryLevel1Id) {
                    this.subIndustryObj = this.industryIdMap[this.infoForm.industryLevel1Id];
                }

                if(this.infoForm.province) {
                    this.provinceCityData.some(item => {
                        if(item.value == this.infoForm.province) {
                            this.provinceSelected = item.label;
                            this.cityList = item.children;
                            return true;
                        }
                    })
                }

                this.descForm = {
                    description: res.description || ''
                }

            }).finally(() =>{
                this.loading = false;
            })
        },

        // getMemberList() {
        //     _request({
        //         url: '/MyCompany/MemberList',
        //         method: 'GET',
        //         data: {
        //             location: 0,
        //             start: 0,
        //             take: 10
        //         }
        //     }).then(res => {
        //         this.memberList = res.list;
        //         this.memberCount = res.total;
        //     })
        // },
        getMemberList() {
            _request({
                url: teamUrl.get_firm_teams_members,
                method: 'POST',
                baseURL: "LbdOpenApi",
                data: {
                    teamId: "0",
                    location: 0,
                    start: 0,
                    take: 10
                }
            }).then(res =>{
                this.memberList = res.list;
                this.memberCount = res.total;
            })
        },

        showInfoForm() {
            this.infoEditing = true;
        },

        showDescForm() {
            this.descEditing = true;
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                if(formName == 'infoForm') {
                    this.submitInfoForm();
                }else {
                    this.submitDescForm();
                }
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        submitInfoForm() {
            if(this.infoFormLoading) {
                return false;
            }
            this.infoFormLoading = true;
            _request({
                url: '/FirmManager/UpdateProfile',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: Object.assign({}, {
                    __RequestVerificationToken: this.token
                }, this.infoForm)
            }).then(res =>{
                shortTips('更新成功!');
                this.infoEditing = false;
                this.getCompanyInfo();
            }).finally(() => {
                this.infoFormLoading = false;
            })
        },
        submitDescForm() {
            if(this.descFormLoading) {
                return false;
            }
            this.descFormLoading = true;
            _request({
                url: '/FirmManager/UpdateDescription',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    description: this.descForm.description,
                    __RequestVerificationToken: this.token
                }
            }).then(res => {
                shortTips('更新成功!');
                this.descEditing = false;
                this.getCompanyInfo();
            }).finally(() => {
                this.descFormLoading = false;
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            if(formName == 'infoForm') {
                this.infoEditing = false;
                if(this.infoForm.province === '') {
                    this.provinceSelected = '';
                }
            }else {
                this.descEditing = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.company-setting {
    padding: 20px;
    height: 100%;

    &-container {
        height: 100%;
        overflow-y: auto;
        background-color: #fff;
        border-radius: 8px;
    }

    &-tab {
        display: flex;
        padding: 0 30px;
        border-bottom: 1px solid #eee;
        li {
            margin-right: 66px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            color: #999;
            cursor: pointer;

            &.active {
                color: $primary;
                cursor: default;
                border-bottom: 4px solid #38BC9D;
            }
        }
    }

    &-main {
        padding: 0 30px;
    }

    &-content {
        // width: 850px;
        width: 1000px;
        // padding: 10px 15px 50px;
        // margin: 20px 0 50px;
        padding: 36px 0;
        // background-color: #fff;

        .info {
            &.info-top {
                margin-bottom: 20px;
                // margin-bottom: 15px;
                // padding-bottom: 15px;
                // border-bottom: 1px solid #f6f6f6;
            }

            .info-text {
                .info-content {
                    padding: 30px 0 30px 16px;
                    word-wrap: break-word;
                }
                h3 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 20px;
                    color: #333333;
                    // margin-bottom: 20px;
                    margin-bottom: 0;
                    padding: 0 10px;
                    border-left: 4px solid #38BC9D;

                    span {
                        font-size: 14px;
                        color: $primary;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                &-list {
                    font-size: 14px;
                    color: #76838f;
                    line-height: 26px;

                    li {
                        display: flex;

                        span {
                            flex-shrink: 0;
                        }

                        a {
                            color: #76838f;

                            &:hover, &:active {
                                color: $primary;
                            }
                        }
                    }
                }

                > span {
                    color: #76838f;
                }
            }

            .info-form {
                .el-form-item {
                    /deep/ .el-form-item__label {
                        text-align: left;
                    }

                    .el-col-8 {
                        width: 240px;

                        &.second-select {
                            margin-left: 50px;
                        }
                    }

                    .el-select {
                        width: 240px;
                    }

                    .el-input {
                        width: 530px;
                    }

                    /deep/ .el-textarea__inner {
                        height: 180px;
                    }
                }
            }

            .desc-text {
                // padding-top: 15px;
                margin-bottom: 20px;
                
                > span {
                    white-space: pre-wrap;
                    word-break: break-word;
                }
            }
        }
    }

    &-members {
        ul {
            li {
                display: inline-block;
                padding: 0 5px 10px 0;

                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 1px solid #ececec;
                }
            }
        }
    }

    &-labels {
        padding: 150px 0;
        text-align: center;
        p {
            // width: 1200px;
            // height: 300px;
            // margin: 20px auto 0;
            // padding-top: 50px;
            margin: 30px 0 0;
            font-size: 16px;
            color: #666;
            text-align: center;
            background-color: #fff;
        }
    }
}
</style>