var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "company-setting" }, [
    _c("div", { staticClass: "company-setting-container" }, [
      _c("ul", { staticClass: "company-setting-tab" }, [
        _c(
          "li",
          {
            class: _vm.activeTab == 0 ? "active" : "",
            on: {
              click: function ($event) {
                return _vm.changeActiveTab(0)
              },
            },
          },
          [_vm._v("资料设置")]
        ),
        false
          ? _c(
              "li",
              {
                class: _vm.activeTab == 1 ? "active" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeActiveTab(1)
                  },
                },
              },
              [_vm._v("标签设置")]
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab == 0,
              expression: "activeTab == 0",
            },
          ],
          staticClass: "company-setting-main",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "company-setting-content",
            },
            [
              _c("div", { staticClass: "info info-top" }, [
                _c("div", { staticClass: "info-text" }, [
                  _c("h3", [
                    _vm._v(
                      "\n                            公司信息\n                            "
                    ),
                    !_vm.infoEditing
                      ? _c("span", { on: { click: _vm.showInfoForm } }, [
                          _c("i", { staticClass: "el-icon-edit-outline" }),
                          _vm._v("编辑"),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info-content" },
                    [
                      !_vm.infoEditing
                        ? _c("ul", { staticClass: "info-text-list" }, [
                            _c("li", [
                              _c("span", [_vm._v("行业：")]),
                              _vm._v(_vm._s(_vm.companyInfo.industry)),
                            ]),
                            _c("li", [
                              _c("span", [_vm._v("规模：")]),
                              _vm._v(
                                _vm._s(_vm.companyInfo.companyScaleDescription)
                              ),
                            ]),
                            _c("li", [
                              _c("span", [_vm._v("城市：")]),
                              _vm._v(_vm._s(_vm.companyInfo.location)),
                            ]),
                            _c("li", [
                              _c("span", [_vm._v("地址：")]),
                              _vm._v(_vm._s(_vm.companyInfo.address)),
                            ]),
                            _c("li", [
                              _c("span", [_vm._v("主页：")]),
                              _vm.companyInfo.website
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.companyInfo.website,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.companyInfo.website))]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        : _c(
                            "el-form",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.infoFormLoading,
                                  expression: "infoFormLoading",
                                },
                              ],
                              ref: "infoForm",
                              staticClass: "info-form",
                              attrs: {
                                model: _vm.infoForm,
                                "label-width": "150px",
                                rules: _vm.infoFormRules,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "行业", required: "" } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "industryLevel1Id",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleIndustryelect,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.infoForm
                                                        .industryLevel1Id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.infoForm,
                                                        "industryLevel1Id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "infoForm.industryLevel1Id",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "请选择",
                                                      value: "-1",
                                                    },
                                                  }),
                                                  _vm._l(
                                                    _vm.industryArray,
                                                    function (industry) {
                                                      return _c("el-option", {
                                                        key: industry.code,
                                                        attrs: {
                                                          label: industry.name,
                                                          value:
                                                            industry.code.toString(),
                                                        },
                                                      })
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "second-select",
                                          attrs: { span: 8 },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "industryId" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.infoForm.industryId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.infoForm,
                                                        "industryId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "infoForm.industryId",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "请选择",
                                                      value: "-1",
                                                    },
                                                  }),
                                                  _vm._l(
                                                    _vm.subIndustryObj,
                                                    function (
                                                      subIndustry,
                                                      code
                                                    ) {
                                                      return _c("el-option", {
                                                        key: code,
                                                        attrs: {
                                                          label: subIndustry,
                                                          value:
                                                            code.toString(),
                                                        },
                                                      })
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "规模",
                                    prop: "companyScale",
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.infoForm.companyScale,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.infoForm,
                                            "companyScale",
                                            $$v
                                          )
                                        },
                                        expression: "infoForm.companyScale",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "请选择", value: "-1" },
                                      }),
                                      _vm._l(
                                        _vm.companyScaleData,
                                        function (companyScale, code) {
                                          return _c("el-option", {
                                            key: code,
                                            attrs: {
                                              label: companyScale,
                                              value: code.toString(),
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "城市", required: "" } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "province" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                    value: _vm.provinceSelected,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleProvinceSelect,
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "请选择",
                                                      value: "-1",
                                                    },
                                                  }),
                                                  _vm._l(
                                                    _vm.provinceCityData,
                                                    function (province) {
                                                      return _c("el-option", {
                                                        key: province.value,
                                                        attrs: {
                                                          label: province.label,
                                                          value: province,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "second-select",
                                          attrs: { span: 8 },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "city" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value: _vm.infoForm.city,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.infoForm,
                                                        "city",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "infoForm.city",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "请选择",
                                                      value: "-1",
                                                    },
                                                  }),
                                                  _vm._l(
                                                    _vm.cityList,
                                                    function (city) {
                                                      return _c("el-option", {
                                                        key: city.value,
                                                        attrs: {
                                                          label:
                                                            city.fullLabel ||
                                                            city.label,
                                                          value:
                                                            city.value.toString(),
                                                        },
                                                      })
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "地址", prop: "address" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入详细地址" },
                                    model: {
                                      value: _vm.infoForm.address,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "address", $$v)
                                      },
                                      expression: "infoForm.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "主页", prop: "website" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入主页网址" },
                                    model: {
                                      value: _vm.infoForm.website,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "website", $$v)
                                      },
                                      expression: "infoForm.website",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.submitForm("infoForm")
                                        },
                                      },
                                    },
                                    [_vm._v("保存")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetForm("infoForm")
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "info-text desc-text" }, [
                  _c("h3", [
                    _vm._v(
                      "\n                            公司介绍\n                            "
                    ),
                    !_vm.descEditing
                      ? _c("span", { on: { click: _vm.showDescForm } }, [
                          _c("i", { staticClass: "el-icon-edit-outline" }),
                          _vm._v("编辑"),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info-content" },
                    [
                      !_vm.descEditing
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.companyInfo.description)),
                          ])
                        : _c(
                            "el-form",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.descFormLoading,
                                  expression: "descFormLoading",
                                },
                              ],
                              ref: "descForm",
                              staticClass: "info-form",
                              attrs: {
                                model: _vm.descForm,
                                rules: _vm.descFormRules,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "description" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入公司介绍",
                                    },
                                    model: {
                                      value: _vm.descForm.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.descForm,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "descForm.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.submitForm("descForm")
                                        },
                                      },
                                    },
                                    [_vm._v("保存")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetForm("descForm")
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "info" }, [
                _c(
                  "div",
                  { staticClass: "info-text company-setting-members" },
                  [
                    _c("h3", [
                      _vm._v(
                        "\n                            公司成员(" +
                          _vm._s(_vm.memberCount) +
                          ")\n                        "
                      ),
                    ]),
                    _c("div", { staticClass: "info-content" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.memberList, function (member) {
                          return _c("li", { key: member.userId }, [
                            member.avatarUrl
                              ? _c("img", { attrs: { src: member.avatarUrl } })
                              : _c("img", {
                                  attrs: {
                                    src: require("@src/assets/images/default-user.png"),
                                  },
                                }),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab == 1,
              expression: "activeTab == 1",
            },
          ],
          staticClass: "company-setting-labels",
        },
        [
          _c("img", {
            attrs: { src: require("@src/assets/images/no-open.png"), alt: "" },
          }),
          _c("p", [_vm._v("该功能暂未开放~")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }